<i18n>
ru:
  additionalLogosBlockHide: Скрыть блок
  additionalLogosBlockTitle: 'Попробуйте больше новых блюд в наших ресторанах: '
  additionalLogosBlockTitleMobile: 'Перейти в ресторан: '
  homePage: Домашняя страница
  search: Поиск
  titleSocialNetworksLinks: 'Наши соц. сети:'
ua:
  additionalLogosBlockHide: Приховати блок
  additionalLogosBlockTitle: 'Спробуйте більше нових страв у наших ресторанах:'
  additionalLogosBlockTitleMobile: 'Перейти до ресторану: '
  homePage: Домашня сторінка
  search: Пошук
  titleSocialNetworksLinks: 'Наші соц. мережі:'
us:
  additionalLogosBlockHide: Hide block
  additionalLogosBlockTitle: 'Try more new meals in our restaurants: '
  additionalLogosBlockTitleMobile: 'Go to a restaurant: '
  homePage: Home page
  search: Search
  titleSocialNetworksLinks: 'Our social networks:'
</i18n>

<template>
  <header-small
    v-if="isExtraSmall"
    :external-links-contacts="externalLinksContacts"
    :external-links-header="externalLinksHeader"
    :header-easter-egg-available="headerEasterEggAvailable"
  />
  <header-umbar
    v-else-if="appConfig.VueSettingsPreRun.HeaderLayout === 'Umbar'"
    :external-links-contacts="externalLinksContacts"
    :external-links-header="externalLinksHeader"
    :header-easter-egg-available="headerEasterEggAvailable"
  />
  <header-wold
    v-else-if="appConfig.VueSettingsPreRun.HeaderLayout === 'Wold'"
    :external-links-contacts="externalLinksContacts"
    :header-easter-egg-available="headerEasterEggAvailable"
  />
  <div
    id="header-groups"
    v-sticky-top="{
      refresh: true,
      onRefresh: updateSticky
    }"
    :class="headerClass"
  >
    <div
      :id="`${uid}-groups-container`"
      class="v-container v-header-groups--container"
    >
      <div
        class="v-header-groups--search-wrapper v-position-relative v-p-0 v-m-0"
        :class="[
          appConfig.VueSettingsPreRun.HeaderEnableSearch ? 'v-d-flex v-flex-row-no-wrap' : 'v-row',
          appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BaradNimras' && !isExtraSmall
            ? 'v-align-items-start'
            : 'v-align-items-center',
          isExtraSmall ? 'v-align-items-end' : 'v-align-items-center'
        ]"
      >
        <div
          v-if="appConfig.VueSettingsPreRun.HeaderEnableSearch"
          :id="`${uid}-search-bar`"
          class="v-header-groups--search-bar v-position-absolute v-row v-pm-shadow"
          :class="{
            'v-header-groups--search-bar__barad-nimras':
              appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BaradNimras'
          }"
          style="width: 0; height: 0; opacity: 0"
          v-on-click-outside="onClickOutside"
        >
          <div
            v-if="showSearch"
            class="v-col-12 v-col-sm-10 v-col-md-5 v-pl-0"
          >
            <menu-search :on-close="onClickOutside" />
          </div>
        </div>
        <div
          :id="`${uid}-groups`"
          class="v-d-flex v-w-100 v-p-0"
          style="max-height: 999px"
        >
          <div
            class="v-col-12 v-col-md-8 v-col-lg-10 v-d-flex"
            :class="`v-header-groups-and-search-wrapper__${appConfig.VueSettingsPreRun.MenuGroupsLayout}`"
          >
            <template v-if="appConfig.VueSettingsPreRun.HeaderEnableSearch">
              <div
                class="v-header-groups--search"
                :class="{
                  'v-header-groups--search__BaradNimras':
                    appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BaradNimras'
                }"
              >
                <div
                  v-if="appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BaradNimras'"
                  class="v-arora-group--barad-nimras--single v-pointer"
                  @click="onSearchClick"
                >
                  <div
                    :id="`${uid}-search-image`"
                    class="v-d-flex v-flex-column v-align-items-center"
                  >
                    <arora-nuxt-image
                      v-if="imageExists(appConfig.VueSettingsPreRun.MenuSearchIcon)"
                      :alt="translate('headerPage.search')"
                      :image="appConfig.VueSettingsPreRun.MenuSearchIcon"
                      :scale="showSearch ? 0 : 1"
                    />
                    <icon-general-search
                      v-else
                      :class="[showSearch ? 'v-search-icon--barad-nimras-open' : '']"
                    />
                    <span
                      class="v-arora-group--barad-nimras-caption v-overflow-hidden"
                      v-show="!showSearch"
                      v-html="translate('headerPage.search')"
                    />
                  </div>
                </div>
                <div
                  v-else
                  class="v-search-button v-pointer"
                  @click="onSearchClick"
                >
                  <icon-general-search />
                </div>
              </div>

              <div
                :class="[
                  appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BaradNimras'
                    ? 'v-header-groups--groups__barad-nimras'
                    : 'v-header-groups--groups',
                  showSearch ? 'v-overflow-hidden' : ''
                ]"
              >
                <menu-groups-page is-header />
              </div>
            </template>
            <div
              v-else
              class="v-header-groups--groups v-header-groups--groups__no-search"
            >
              <menu-groups-page is-header />
            </div>
          </div>
          <div
            v-if="!isExtraSmall"
            class="v-col-12 v-col-md-4 v-col-lg-2 v-d-flex v-flex-end"
          >
            <lazy-cart-small-basket />
          </div>
        </div>
      </div>

      <div class="v-col-12">
        <menu-subgroups-page />
      </div>
    </div>
    <ClientOnly>
      <lazy-cart-order-cancel />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import type { ExternalLink } from '~types/settingsVariants'

import { useCommon, useWindowSize, vOnClickOutside, vStickyTop } from '@arora/common'

import gsap from 'gsap'

const appConfig = useAppConfig()
const router = useRouter()

const { translate } = useI18nSanitized()
const { isExtraSmall } = useWindowSize()
const { imageExists } = useImageInfo()
const { stringIsNullOrWhitespace } = useCommon()

const clientStore = useClientStore()

const uid = useId()

const showSearch = ref<boolean>(false)
const headerClass = ref<string>('v-header-groups')

let currentSticky = false

const externalLinksContacts = ref<ExternalLink[]>(
  (appConfig.VueSettingsPreRun.ExternalLinks as ExternalLink[])
    .filter((l) => l.Type === 'contact')
    .sort((a, b) => a.SortWeight - b.SortWeight)
)

const externalLinksHeader = ref<ExternalLink[]>(
  appConfig.VueSettingsPreRun.HeaderEnableExternalLinks
    ? (appConfig.VueSettingsPreRun.ExternalLinks as ExternalLink[])
        .filter(
          (l) =>
            l.Type === (appConfig.VueSettingsPreRun.HeaderUseFooterExternalLinks ? 'footer' : 'header')
        )
        .sort((a, b) => a.SortWeight - b.SortWeight)
    : ([] as ExternalLink[])
)

const headerEasterEggAvailable = computed<boolean>(
  () => !stringIsNullOrWhitespace(clientStore.ClientState.data?.EasterEggPromo)
)

function updateSticky(isSticky: boolean): void {
  if (isSticky !== currentSticky) {
    currentSticky = isSticky

    gsap.set(`header`, {
      duration: 0.1,
      ease: 'sine.inOut',
      opacity: isSticky ? 0 : 1
    })

    const typeHeader =
      appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BaradNimras'
        ? 'v-header-groups v-header-groups__barad-nimras'
        : 'v-header-groups'

    headerClass.value = isSticky ? `${typeHeader} v-fixed-top` : typeHeader
  }
}

function onSearchClick(): void {
  if (!showSearch.value) {
    gsap.to(`#${uid}-groups`, {
      duration: 0.4,
      ease: 'sine.inOut',
      maxHeight: 60,
      opacity: 0
    })

    gsap.to(`#${uid}-search-image`, {
      duration: 0.4,
      ease: 'sine.inOut',
      maxHeight: 60,
      opacity: 0
    })

    gsap.set(`#${uid}-search-bar`, {
      height: 60,
      width: (document.getElementById(`${uid}-groups-container`)?.offsetWidth ?? 500) - 5
    })

    gsap.to(`#${uid}-search-bar`, {
      duration: 0.4,
      ease: 'sine.inOut',
      onComplete: () => {
        showSearch.value = true
      },
      opacity: 1
    })
  }
}

function onClickOutside(): void {
  if (showSearch.value) {
    gsap.to(`#${uid}-groups`, {
      duration: 0.4,
      ease: 'sine.inOut',
      maxHeight: 999,
      opacity: 1
    })

    gsap.to(`#${uid}-search-image`, {
      duration: 0.4,
      ease: 'sine.inOut',
      maxHeight: 99,
      opacity: 1
    })

    gsap.to(`#${uid}-search-bar`, {
      duration: 0.4,
      ease: 'sine.inOut',
      onComplete: () => {
        gsap.set(`#${uid}-search-bar`, {
          height: 0,
          width: 0
        })

        showSearch.value = false
      },
      opacity: 0
    })
  }
}
router.beforeEach(() => {
  onClickOutside()
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

header {
  background: variables.$HeaderBackgroundColor;
  color: variables.$HeaderColor;
  width: 100%;
  position: relative;
  z-index: 1002; //behind popup
}

#header-groups {
  background: variables.$HeaderBackgroundColor;
  margin-bottom: 1rem;
}

.v-header {
  border-bottom: 1px solid rgba(125, 125, 125, 0.1);
}

.v-header-logo {
  img {
    min-height: 25px;
    max-height: 150px;
  }

  svg {
    min-height: 25px;
    max-height: 150px;
  }
}

.v-header-groups {
  @include mixins.trans;

  background: variables.$HeaderBackgroundColor;
  color: variables.$HeaderColor;
  box-shadow: variables.$HeaderGroupsShadow;

  width: 100%;
  position: relative;
  z-index: 1001; //behind popup

  &--search-wrapper {
    max-width: 100%;
  }

  &--container {
    min-height: 60px;
  }

  &--search {
    .v-search-button {
      @include mixins.trans;

      width: 28px;
      height: 28px;

      path {
        stroke: variables.$SecondaryBackgroundColor;
      }

      &:hover {
        path {
          stroke: variables.$SecondaryBackgroundColorDarken;
        }
      }
    }
  }

  &--search__BaradNimras {
    @include mixins.sm {
      margin-right: 20px;
    }

    svg {
      @include mixins.trans;

      width: 60px;
      height: 60px;

      @include mixins.sm {
        width: 30px;
        height: 30px;
      }

      &.v-search-icon--barad-nimras-open {
        width: 100%;
        height: auto;
        margin: 0.5rem 0;
      }

      path {
        stroke: variables.$SecondaryBackgroundColor;
      }
    }
  }

  &--search-bar {
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    background: variables.$HeaderBackgroundColor;

    display: flex;
    justify-content: center;
    flex-direction: column;

    .v-arora-input {
      margin: 0.5rem 0 auto;

      input {
        box-shadow: none;
      }
    }
  }
}

.v-header-groups-and-search-wrapper {
  overflow: hidden;

  &__BaradNimras {
    .v-header-groups--search {
      margin-right: 32px;

      @include mixins.sm {
        margin-right: 16px;
      }
    }
  }
  &__BarrowDowns {
    align-items: start;
    margin: 10px 0 auto;

    @include mixins.sm {
      margin: 2px 0 auto;
    }

    .v-header-groups--search {
      margin: 7px 1.25rem 0 0;
    }

    .v-header-groups--groups {
      margin: auto 0;
    }
  }
  &__Orthanc {
    align-items: center;
    margin: 2px 0 0;
    padding-right: 1rem;

    .v-header-groups--search {
      padding: 0.5rem 0.9rem 1rem 0;
      margin: 0;

      @include mixins.sm {
        padding-bottom: 0.5rem;
      }
    }
  }
  &__BaradDur {
    align-items: start;
    margin: 10px 0 auto;

    .v-header-groups--search {
      margin: 7px 1.25rem 0 0;
    }

    .v-header-groups--groups {
      margin: auto 0;

      @include mixins.sm {
        max-width: 285px;
      }
    }

    @include mixins.sm {
      margin: 2px 0 auto;
    }
  }
}

.v-header-groups--groups {
  width: calc(100% - (30px + 2.25rem));

  @include mixins.sm {
    width: calc(100% - (30px + 3rem));
  }

  &__no-search {
    width: 100%;
  }
}
.v-header-groups--groups__barad-nimras {
  max-width: 100%;
  overflow: hidden;
}
</style>

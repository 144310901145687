<template>
  <div
    class="v-group-orthanc"
    data-test-id="menu-groups-link-container"
  >
    <arora-tabs
      can-be-unselected
      prefetch-links
      with-links
      :space-between="20"
      :tabs="menuStore.GroupsForDisplay"
      data-test-id="menu-groups-link"
      v-model:selected="selectedGroupIdLocal"
    />
  </div>
</template>

<script setup lang="ts">
import type { GUID } from '@arora/common'

const menuStore = useMenuStore()

const selectedGroupIdLocal = computed<GUID | undefined>({
  get: () => menuStore.CurrentGroupId,
  set: (value) => {
    const foundGroup = menuStore.GroupsForDisplay.find((g) => g.ID === value)

    navigateTo(foundGroup ? foundGroup.Link : null)
  }
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-group-orthanc {
  max-width: 100%;
  margin: 10px 0;

  @include mixins.sm {
    margin: 7px 0 0;
  }

  .v-arora-tabs {
    @include mixins.sm {
      padding: 0;
      margin: 0;
    }

    .swiper {
      min-height: 2.25rem;
    }

    .v-arora-tabs--single,
    .v-arora-tabs a.v-arora-tabs--single {
      padding-top: 0;
      color: variables.$HeaderColor;
      font-family: variables.$FontFamilyMenuGroups;

      @include mixins.trans;

      &:hover {
        color: variables.$SecondaryBackgroundColorDarken;

        @include mixins.sm {
          color: variables.$HeaderColor;
        }
      }

      &__selected {
        color: variables.$SecondaryBackgroundColor;
        font-weight: 600;
      }
    }
  }
}
</style>

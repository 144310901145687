<template>
  <div
    :class="`v-card-mesh-wrapper v-gap-${meshGap}`"
    :data-test-id="dataTestId"
    :style="`--meshGap: ${meshGap}px; --flex: ${flex}; --items: ${adaptiveLocal}; margin-bottom: ${meshGap}px;`"
  >
    <div
      v-for="(item, index) in itemsCalc"
      :key="`card-flex-mesh-${uid}-${index}`"
      class="v-card-flex"
      :class="className"
      :data-test-id="dataTestId && `${dataTestId}-item`"
    >
      <slot
        v-if="$slots.item"
        name="item"
        v-bind="item"
      />
      <slot
        v-else-if="$slots.indexedItem"
        name="indexedItem"
        v-bind="{ item: item, index: index }"
      />
    </div>
  </div>
</template>

<script setup lang="ts" generic="T">
import { type AutoTest, useWindowSize, WindowSize } from '@arora/common'

const {
  adaptiveItems = 4,
  className = 'v-card-flex-default',
  items,
  maxItems = 4,
  meshGap = 30,
  minItems = 1
} = defineProps<
  AutoTest & {
    items: Map<unknown, T> | T[]
    className?: string
    maxItems?: number
    minItems?: number
    meshGap?: number
    adaptiveItems?: number
  }
>()
const emit = defineEmits(['update:adaptiveItems'])

const { windowSize } = useWindowSize()
const appConfig = useAppConfig()

const itemsCalc = computed<T[]>(() =>
  items instanceof Map ? Array.from(items, ([_key, value]) => value) : items
)

const uid = ref<string>('')
const adaptive = new Map<number, number>()

function calculateWidth(): void {
  const cardWidth = appConfig.VueSettingsPreRun.Theme.ContainerWidth / maxItems

  for (const [, value] of Object.entries(WindowSize)) {
    const valueNumber = Number(value)
    if (!Number.isNaN(valueNumber)) {
      if (valueNumber === 0) {
        adaptive.set(valueNumber, minItems)
      } else {
        const result = Math.floor(
          Math.min(valueNumber, appConfig.VueSettingsPreRun.Theme.ContainerWidth) / cardWidth
        )
        adaptive.set(valueNumber, Math.max(result, minItems))
      }
    }
  }
}

onMounted(() => {
  uid.value = useId()
  //widthApprox ширина карточки = ширина экрана / на настройку карточек

  calculateWidth()

  onResizeEvent()
})

function onResizeEvent(): void {
  adaptiveLocal.value = adaptive.get(windowSize.value) ?? 1
  emit('update:adaptiveItems', adaptiveLocal.value)
}
const flex = computed<string>(() => `0 0 ${100 / adaptiveLocal.value}%`)

watch(
  () => windowSize.value,
  async (newState, oldState) => {
    if (newState !== oldState) {
      onResizeEvent()
    }
  }
)
watch(
  () => minItems,
  async (newState, oldState) => {
    if (newState !== oldState) {
      calculateWidth()
    }
  }
)
const adaptiveLocal = ref<number>(adaptiveItems)
</script>

<style lang="scss">
@use '~/assets/variables';

$meshGap: var(--meshGap, 15px);
$flex: var(--flex, auto);
$items: var(--items, 4);
$width: calc(100% / $items);
$widthGapWithFix: calc((($items - 1) * $meshGap) / $items);

.v-card-mesh-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: $meshGap;

  width: 100%;
  margin-bottom: 1.1rem;
}

.v-card-flex {
  display: flex;
  flex: $flex;
  max-width: calc($width - $widthGapWithFix);
  background: variables.$BodyElementsBackground;
  border-radius: variables.$BorderRadius;
  box-shadow: variables.$CardShadow;
  position: relative;
}

.v-card-flex-default {
  flex-direction: column;
}
</style>

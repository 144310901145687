<template>
  <transition
    appear
    mode="out-in"
    name="bounce"
  >
    <div
      v-if="loaded"
      class="webim_button webim_basic_button v-pointer v-webim-fab"
    >
      <icon-general-chat />
    </div>
  </transition>
</template>

<script setup lang="ts">
const webimScriptKey: Readonly<string> = 'webim-script-id'

const loaded = ref<boolean>(false)

const appConfig = useAppConfig()

onMounted(() => {
  if (!appConfig.RestaurantSettingsPreRun.WebimAccountName) return

  window.webim = {
    accountName: appConfig.RestaurantSettingsPreRun.WebimAccountName,
    domain: `${appConfig.RestaurantSettingsPreRun.WebimAccountName}.webim.ru`
  }

  let scriptWebim = document.querySelector(`#${webimScriptKey}`)
  if (scriptWebim) {
    loaded.value = true
  } else {
    scriptWebim = document.createElement('script')

    scriptWebim.setAttribute('id', webimScriptKey)
    scriptWebim.setAttribute(
      'src',
      `https://${appConfig.RestaurantSettingsPreRun.WebimAccountName}.webim.ru/js/button.js`
    )

    document.head.append(scriptWebim)

    scriptWebim.addEventListener('load', () => (loaded.value = true))
  }
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-webim-fab {
  z-index: 9;
  overflow: hidden;
  box-shadow: variables.$FloatShadow;
  width: 85px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 4px solid variables.$PrimaryBackgroundColor;
  background: variables.$PrimaryColor;

  .v-chat-icon {
    width: 36px;
    path {
      fill: variables.$PrimaryBackgroundColor;
    }
    rect {
      fill: variables.$PrimaryColor;
    }
  }

  @include mixins.md {
    max-width: 60px !important;
    max-height: 60px !important;
    padding: 16px;
  }
}

.webim-root .webim-processing-personal-data-block a {
  color: variables.$LinkColor;
}

.webim-root .webim-processing-personal-data-block .webim-ico-checkbox-checked {
  svg {
    path {
      fill: variables.$PrimaryBackgroundColor;
    }
  }
}
</style>

import type { IMetric } from '~types/restaurantStore'

import { ExternalEvent } from '~api/consts'
import generalApi from '~api/general'

export default class GoogleMetric implements IMetric {
  sendMetricAddToCart(): Promise<void> {
    return Promise.resolve()
  }

  sendMetricAfterOrder(): Promise<void> {
    return Promise.resolve()
  }
  async sendMetricBeforeOrder(): Promise<void> {
    await generalApi.externalEvent(ExternalEvent.GABeginCheckout)
  }

  async sendMetricCartView(isSmallCart: boolean): Promise<void> {
    await generalApi.externalEvent(
      isSmallCart ? ExternalEvent.GASmallBasketView : ExternalEvent.GACartView
    )
  }

  sendMetricRemoveFromCart(): Promise<void> {
    return Promise.resolve()
  }

  SendMetricsProductView(): Promise<void> {
    return Promise.resolve()
  }
}

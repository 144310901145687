<template>
  <arora-nuxt-link
    class-name="v-group-helms-deep v-position-relative"
    :href="group.Link"
    :label="sanitize(group.Name)"
  >
    <div
      v-if="isSmall && imageExists(group.ImageForMobile)"
      class="v-group-helms-deep--image"
    >
      <arora-nuxt-image
        :alt="sanitize(group.Name)"
        :image="group.ImageForMobile"
      />
    </div>
    <div
      v-else-if="imageExists(group.Image)"
      class="v-group-helms-deep--image"
    >
      <arora-nuxt-image
        :alt="group.Name"
        :image="group.Image"
      />
    </div>
    <div
      v-else
      class="v-group-helms-deep--image v-group-helms-deep--image__placeholder"
    >
      <icon-menu-placeholder />
    </div>
    <div
      class="v-group-helms-deep--title v-position-absolute"
      v-html="sanitize(group.Name)"
    />
  </arora-nuxt-link>
</template>

<script setup lang="ts">
import type { Group } from '~types/menuStore'

import { useWindowSize } from '@arora/common'

defineProps<{
  group: Group
}>()
const { sanitize } = useI18nSanitized()
const { isSmall } = useWindowSize()
const { imageExists } = useImageInfo()
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-group-helms-deep-card {
  @include mixins.trans;

  &:hover {
    scale: 1.1;
  }

  .v-group-helms-deep {
    width: 100%;

    &--image {
      height: fit-content;
      margin-bottom: -4px;

      &__placeholder {
        display: flex;
        justify-content: center;
        align-groups: center;

        height: 100%;

        background: variables.$BodyTextColorLight;
        color: variables.$BodyTextColor;
        border-radius: variables.$BorderRadius;
        opacity: 0.5;
        width: 100%;

        svg {
          width: 160px;
          height: auto;
        }
      }

      .v-img-fluid {
        height: auto;
        width: 100%;
        border-radius: variables.$BorderRadius;
      }
    }

    &--title {
      left: 20px;
      bottom: 20px;
      color: variables.$BodyBackground;
      background: variables.$BodyTextColor;
      padding: 6px 12px;
      border-radius: variables.$BorderRadiusInput;
      font-size: 1.425rem;
      font-weight: 600;
      margin-right: 20px;
      width: auto;
    }
  }
}
</style>

<template>
  <transition
    mode="out-in"
    name="fade"
  >
    <div
      v-if="subgroups && subgroups.length > 0"
      class="v-subgroups__dol-amroth v-scrollbar v-d-flex v-flex-row-no-wrap v-pt-xs"
      :class="{ 'v-pb-md': isSmall }"
      data-test-id="menu-subgroups-links-container"
    >
      <arora-tabs
        can-be-unselected
        with-links
        :space-between="16"
        :tabs="subgroups"
        data-test-id="menu-subgroups-link"
        v-model:selected="selectedSubgroup"
      />
    </div>
  </transition>
</template>

<script setup lang="ts">
import type { Group } from '~types/menuStore'

import { type GUID, useWindowSize } from '@arora/common'

import { Guid } from '~api/consts'

const { subgroups } = defineProps<{ subgroups: Group[] | null | undefined }>()

const route = useRoute()

const { isSmall } = useWindowSize()

const selectedSubgroup = computed<GUID>({
  get: () => (route.meta.SubgroupID ? (route.meta.SubgroupID as GUID) : Guid.Empty),
  set: (value) => {
    const foundGroup = subgroups!.find((g) => g.ID === value)

    if (foundGroup) navigateTo(foundGroup.Link)
  }
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-subgroups__dol-amroth {
  overflow-y: auto;

  @include mixins.sm {
    margin-top: 1rem;
  }

  .v-arora-tabs {
    @include mixins.sm {
      padding: 0;
      margin: 0;
    }

    .swiper {
      min-height: 2rem;
    }

    .v-arora-tabs--single,
    .v-arora-tabs a.v-arora-tabs--single {
      color: variables.$HeaderColor;
      opacity: 0.5;
      font-size: variables.$TextSizeMain;

      @include mixins.trans;

      &:hover {
        opacity: 1;
        color: variables.$SecondaryBackgroundColorDarken;

        @include mixins.sm {
          color: variables.$HeaderColor;
        }
      }

      &__selected {
        opacity: 1;
        color: variables.$SecondaryBackgroundColor;
        font-weight: 600;
      }
    }
  }
}
</style>

import type { CartItem, OrderInfoItem } from '~types/clientStore'
import type { CartModifier, HaveCaloricValues, Measurable } from '~types/common'
import type {
  Option,
  Product,
  ProductConstructor,
  ProductInList,
  ProductOrOptionFilterable,
  RemovableIngredient
} from '~types/menuStore'

import { type GUID, useCommon, useWindowSize } from '@arora/common'

import { Guid, OrderType, ProductApiType } from '~api/consts'

type productComposable = {
  applyProductsFilter: (product: ProductOrOptionFilterable) => boolean
  getDescriptionWithoutRemovedIngredients: (
    product: CartItem | OrderInfoItem,
    ingredients: RemovableIngredient[]
  ) => string
  getIngredientOptionIfExist: (
    productInList: ProductInList,
    ingredient: ProductInList
  ) => Option | ProductInList
  getModifiersWithoutIngredientsByProduct: (
    products: (CartItem | OrderInfoItem)[],
    removedIngredientsByProduct: Record<GUID | number, RemovableIngredient[]>
  ) => Record<GUID | number, string>
  getRemovedIngredientsByProduct: (
    products: (CartItem | OrderInfoItem)[]
  ) => Record<GUID | number, RemovableIngredient[]>
  getSameProductsInCart: (productID: GUID, withMods?: boolean) => CartItem[]
  hasCaloric: (product: (HaveCaloricValues & Measurable) | undefined) => boolean
  isProductUnavailable: (product: ProductOrOptionFilterable) => boolean
  makeDefaultOption: (product: ProductInList) => void
  openProductPopup: (product: ProductInList) => Promise<void>
  setProductModifiersToZero: (productID: GUID, excludeArray?: GUID[]) => void
  viewProduct: (product: ProductInList, price: number) => void
}

type productWithIngredients = {
  ID: GUID | number
  RemovableIngredients: RemovableIngredient[]
  Modifiers: GUID[]
}
export default function useProduct(): productComposable {
  const clientStore = useClientStore()
  const popupStore = usePopupStore()
  const menuStore = useMenuStore()
  const restaurantStore = useRestaurantStore()
  const appConfig = useAppConfig()

  const { objectKeys } = useCommon()

  const { isExtraSmall } = useWindowSize()
  const { clean, sanitize, translate } = useI18nSanitized()

  function getModIDsArray(productID: GUID, cartItem: CartItem): GUID[] {
    const cartItemMods = cartItem.Modifiers.map((mod) => mod.ID)
    const productMods = objectKeys(menuStore.SelectedModifiersPerProduct.get(productID) ?? [])

    return [...new Set([...cartItemMods, ...productMods])]
  }

  function makeDefaultOption(product: ProductInList): void {
    const optionsKeys = product.Options ? objectKeys(product.Options) : []
    let selectedOption = null

    if (optionsKeys.length > 0) {
      for (const key of optionsKeys)
        for (const option of Object.values(product?.Options[key])) {
          if (
            option.IsDefault &&
            !menuStore.StopListOptionIds.has(option.ID) &&
            !menuStore.NonActiveProductIds.has(option.ID)
          ) {
            selectedOption = option
          }
        }

      if (!selectedOption) {
        selectedOption = Object.values(product.Options)
          .flat()
          .find(
            (option: Option) =>
              !menuStore.StopListOptionIds.has(option.ID) &&
              !menuStore.NonActiveProductIds.has(option.ID)
          )
      }
    }

    if (selectedOption) menuStore.SelectedOptionsPerProduct.set(product.ID, selectedOption)
  }

  async function openProductPopup(product: ProductInList): Promise<void> {
    switch (product.ProductApiType) {
      case ProductApiType.Default:
        await popupStore.openPopup({
          popupClosable: true,
          popupName: 'menuProductPopup',
          popupProperties: new Map<string, unknown>([['productId', product.ID]])
        })
        break
      case ProductApiType.Half:
        if (isExtraSmall.value) {
          await navigateTo(product.Link) //slots will have no popup in mobile version
        } else {
          await popupStore.openPopup({
            popupClosable: true,
            popupName: 'menuSlotsHalfPopup',
            popupProperties: new Map<string, unknown>([['slotId', product.ID]])
          })
        }
        break
      case ProductApiType.Quarter:
        if (isExtraSmall.value) {
          await navigateTo(product.Link) //slots will have no popup in mobile version
        } else {
          await popupStore.openPopup({
            popupClosable: true,
            popupName: 'menuSlotsQuarterPopup',
            popupProperties: new Map<string, unknown>([['slotId', product.ID]])
          })
        }
        break
      case ProductApiType.Lunch:
        if (isExtraSmall.value) {
          await navigateTo(product.Link) //slots will have no popup in mobile version
        } else {
          await popupStore.openPopup({
            popupClosable: true,
            popupName: 'menuSlotsLunchPopup',
            popupProperties: new Map<string, unknown>([['slotId', product.ID]])
          })
        }
        break
      case ProductApiType.Combine:
        //TODO: support combo?
        break
      case ProductApiType.Constructor:
        await callForPopupConstructor(product.ID)
        break
      case ProductApiType.BigConstructor:
        await navigateTo(product.Link) //big constructor has no popup
        break
    }
  }

  async function callForPopupConstructor(constructorId: GUID): Promise<void> {
    const constructor: ProductConstructor | undefined = appConfig.Constructors.find(
      (constructor) => constructor.ID === constructorId
    )

    if (!constructor) {
      await popupStore.showWarning(translate('productInListPage.noConstructor'))

      return
    }

    const filledCategories =
      constructor.Categories?.filter((cat) => {
        return cat.ConstructorCategoryModifiers.length > 0
      }) ?? []

    await (filledCategories.length > 0
      ? popupStore.openPopup({
          popupClosable: true,
          popupName: 'menuConstructorPopup',
          popupProperties: new Map<string, unknown>([['constructorId', constructorId]])
        })
      : popupStore.openPopup({
          popupClosable: true,
          popupName: 'menuConstructorPopupSmall',
          popupProperties: new Map<string, unknown>([['constructorId', constructorId]])
        }))
  }

  function hasCaloric(product: (HaveCaloricValues & Measurable) | undefined): boolean {
    if (!product) return false

    return (
      product.Weight > 0 ||
      product.Quantity > 0 ||
      product.EnergyAmount > 0 ||
      product.ProteinsAmount > 0 ||
      product.FatAmount > 0 ||
      product.CarbohydrateAmount > 0
    )
  }

  function getIngredientOptionIfExist(
    baseProduct: Product | ProductInList,
    ingredient: ProductInList
  ): Option | Product | ProductInList {
    if (Object.keys(ingredient.Options ?? []).length > 0) {
      return (
        Object.values(ingredient.Options ?? [])
          .flat()
          .find((o) => o.ID === baseProduct.IngredientsIDsWithOptionID[ingredient.ID]) ?? ingredient
      )
    }

    return ingredient
  }

  function setProductModifiersToZero(productId: GUID, excludeArray: GUID[] = []): void {
    const modifiersInStore = menuStore.SelectedModifiersPerProduct.get(productId)

    if (!modifiersInStore) return
    for (const key of Object.keys(modifiersInStore)) {
      if (!excludeArray.includes(key as GUID)) modifiersInStore[key as GUID] = 0
    }

    menuStore.SelectedModifiersPerProduct.set(productId, modifiersInStore)
  }

  function getSameProductsInCart(productID: GUID, withMods = false): CartItem[] {
    if (Guid.IsNullOrEmpty(productID)) return []

    const result: CartItem[] = []

    const content = clientStore.ClientState.data?.Cart?.Content ?? []
    if (content.length === 0) return []

    let cartItemsFiltered = content.filter(
      (cartItem: CartItem) => !cartItem.Removed && cartItem.ProductID === productID
    )

    const option = menuStore.SelectedOptionsPerProduct.get(productID)?.ID

    if (option) {
      cartItemsFiltered = cartItemsFiltered.filter((cartItem: CartItem) => cartItem.OptionID === option)
    }

    if (withMods) {
      for (const cartItem of cartItemsFiltered) {
        const keys = getModIDsArray(productID, cartItem)
        let passedCheck = true
        for (const key of keys) {
          const modInCart =
            (cartItem.Modifiers ?? []).find((sm: CartModifier) => sm.ID === key)?.Count ?? 0

          const mods = menuStore.SelectedModifiersPerProduct.get(productID)

          passedCheck = passedCheck && !!mods && mods[key] === modInCart
        }

        if (passedCheck) {
          result.push(cartItem)
        }
      }
    } else {
      return cartItemsFiltered
    }

    return result
  }

  function getRemovedIngredientsByProduct(
    products: (CartItem | OrderInfoItem)[]
  ): Record<GUID | number, RemovableIngredient[]> {
    const removedIngredientsByProductLocal: Record<GUID | number, RemovableIngredient[]> = {}
    let productsLocal: productWithIngredients[] = []

    if (products.length > 0) {
      productsLocal =
        'Product' in products[0]
          ? (products as CartItem[]).map((cartProduct) => {
              return {
                ID: cartProduct.ID,
                Modifiers: cartProduct.Modifiers.map((mod) => mod.ModifierID),
                RemovableIngredients: cartProduct.Product.RemovableIngredients
              }
            })
          : (products as OrderInfoItem[]).map((cartProduct, index) => {
              return {
                ID: index,
                Modifiers: objectKeys(cartProduct.Modifiers),
                RemovableIngredients: cartProduct.RemovableIngredients
              }
            })

      for (const cartItem of productsLocal) {
        removedIngredientsByProductLocal[cartItem.ID] = cartItem.RemovableIngredients.filter(
          (ing) => !Guid.IsNullOrEmpty(ing.ModifierId) && cartItem.Modifiers.includes(ing.ModifierId!)
        )
      }
    }

    return removedIngredientsByProductLocal
  }

  function getModifiersWithoutIngredientsByProduct(
    products: (CartItem | OrderInfoItem)[],
    removedIngredientsByProduct: Record<GUID | number, RemovableIngredient[]>
  ): Record<GUID | number, string> {
    const modifiersWithoutIngredientsByProductLocal: Record<GUID | number, string> = {}

    if (products.length > 0) {
      if ('Product' in products[0]) {
        for (const cartItem of products) {
          const cartItemLocal = cartItem as CartItem
          const actuallyRemovedIngredients = new Set(
            removedIngredientsByProduct[cartItemLocal.ID].map((ing) => ing.ModifierId)
          )
          modifiersWithoutIngredientsByProductLocal[cartItemLocal.ID] = cartItemLocal.Modifiers.filter(
            (mod) => !actuallyRemovedIngredients.has(mod.ModifierID)
          )
            .map((modifier) => sanitize(`${modifier.Name} x ${modifier.Count}`))
            .join(', ')
        }
      } else {
        for (const cartItemIndex in products) {
          const cartItem = products[cartItemIndex] as OrderInfoItem
          const actuallyRemovedIngredients = new Set(
            removedIngredientsByProduct[cartItemIndex].map((ing) => ing.ModifierId)
          )

          modifiersWithoutIngredientsByProductLocal[cartItemIndex] = objectKeys(cartItem.Modifiers)
            .filter((mod) => !actuallyRemovedIngredients.has(mod))
            .map((modifier) =>
              translate('orderCompleteItems.modString', {
                count: cartItem.Modifiers[modifier],
                mod: cartItem.ModifiersNames[modifier]
              })
            )
            .join(', ')
        }
      }
    }

    return modifiersWithoutIngredientsByProductLocal
  }

  function getDescriptionWithoutRemovedIngredients(
    product: CartItem | OrderInfoItem,
    ingredients: RemovableIngredient[]
  ): string {
    const productRemovableIngredients =
      'Product' in product ? product.Product.RemovableIngredients : product.RemovableIngredients

    const actuallyRemovedIngredients = new Set(ingredients.map((ing) => ing.ModifierId))

    const finalString = productRemovableIngredients
      .filter(
        (ing) => Guid.IsNullOrEmpty(ing.ModifierId) || !actuallyRemovedIngredients.has(ing.ModifierId)
      )
      .reduce((totalSum, current) => {
        let total = totalSum
        total += Guid.IsNullOrEmpty(current.ModifierId)
          ? sanitize(`${current.Title}${current.Delimiter} `)
          : clean(`${current.Title}${current.Delimiter} `)
        return total
      }, '')
      .trim()

    if (finalString[finalString.length - 1] === ',') {
      return finalString.slice(0, finalString.length - 1)
    }

    return finalString
  }

  function applyProductsFilter(product: ProductOrOptionFilterable): boolean {
    if (appConfig.VueSettingsPreRun.MenuPageHideUnavailableByOrder) {
      if (!product.AvailableByOrderType) return false

      if (!product.AvailableByOrderType.CourierDelivery && clientStore.courierDelivery) return false

      if (!product.AvailableByOrderType.NoShipment && clientStore.selfService) return false

      if (!product.AvailableByOrderType.InHall && clientStore.inHall) return false
    }

    if (appConfig.VueSettingsPreRun.MenuPageHideStopList)
      return !menuStore.StopListProductIds.has(product.ID)

    return true
  }

  function isProductUnavailable(product: ProductOrOptionFilterable): boolean {
    const optionOfProduct = menuStore.SelectedOptionsPerProduct.get(product.ID)

    if (!appConfig.VueSettingsPreRun.MenuPageHideUnavailableByOrder) {
      switch (clientStore.ClientState.data?.OrderType ?? OrderType.Default) {
        case OrderType.CourierDelivery: {
          if (optionOfProduct && !optionOfProduct.AvailableByOrderType.CourierDelivery) return true
          else if (!product.AvailableByOrderType.CourierDelivery) return true

          break
        }
        case OrderType.NoShipment: {
          if (optionOfProduct && !optionOfProduct.AvailableByOrderType.NoShipment) return true
          else if (!product.AvailableByOrderType.NoShipment) return true

          break
        }
        case OrderType.InHall: {
          if (optionOfProduct && !optionOfProduct.AvailableByOrderType.InHall) return true
          else if (!product.AvailableByOrderType.InHall) return true

          break
        }
      }
    }

    if (!appConfig.VueSettingsPreRun.MenuPageHideStopList)
      return optionOfProduct
        ? menuStore.StopListOptionIds.has(optionOfProduct.ID) ||
            menuStore.StopListProductIds.has(product.ID)
        : menuStore.StopListProductIds.has(product.ID)

    return false
  }

  function viewProduct(product: ProductInList, price: number): void {
    restaurantStore.Metrics?.SendMetricsProductView(
      product.GroupID,
      product.ID,
      product.Name,
      price,
      1,
      menuStore.SelectedOptionsPerProduct.get(product.ID)?.ID
    )

    menuStore.refreshRelated(product.ID)
  }

  return {
    applyProductsFilter,
    getDescriptionWithoutRemovedIngredients,
    getIngredientOptionIfExist,
    getModifiersWithoutIngredientsByProduct,
    getRemovedIngredientsByProduct,
    getSameProductsInCart,
    hasCaloric,
    isProductUnavailable,
    makeDefaultOption,
    openProductPopup,
    setProductModifiersToZero,
    viewProduct
  }
}

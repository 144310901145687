import gsap from 'gsap'

export default defineNuxtPlugin(() => {
  const appConfig = useAppConfig()

  onNuxtReady(async () => {
    gsap.to(`#v-app-loader`, {
      backdropFilter: 'blur(0px)',
      duration: 0.15,
      onComplete: () => {
        appConfig.LoadingComplete = true
        if (!import.meta.dev && import.meta.client)
          navigator.sendBeacon(
            `/api/event-time?type=loader-gone&time=${Math.round(Date.now() - window.startTime)}`
          )
      },
      opacity: 0
    })
  })
})

<template>
  <ul
    class="v-arora-group--barrow-downs"
    data-test-id="menu-groups-link-container"
  >
    <li
      v-for="group in menuStore.GroupsForDisplay"
      :key="group.ID"
    >
      <arora-nuxt-link
        prefetch
        :href="group.Link"
        :label="group.Name"
        data-test-id="menu-groups-link"
      >
        <span
          :class="
            group.ID === menuStore.CurrentGroupId
              ? 'v-group--barrow-downs--title v-active'
              : 'v-group--barrow-downs--title'
          "
          v-html="group.Name"
        />
      </arora-nuxt-link>
    </li>
  </ul>
</template>

<script setup lang="ts">
const menuStore = useMenuStore()
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-arora-group--barrow-downs {
  padding: 10px 0 1rem;
  margin: 0;

  list-style: none;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 24px;

  .v-group--barrow-downs--title {
    color: variables.$HeaderColor;
    font-size: 1.25rem;
    font-family: variables.$FontFamilyMenuGroups;

    @include mixins.trans;

    &.v-active {
      color: variables.$PrimaryBackgroundColor;
      font-weight: 600;
    }

    &:hover {
      color: variables.$PrimaryBackgroundColorDarken;

      @include mixins.sm {
        color: variables.$HeaderColor;
      }
    }

    @include mixins.sm {
      font-size: 1.1rem;
    }
  }
}
</style>

<template>
  <menu-subgroups-dol-guldur
    v-if="appConfig.VueSettingsPreRun.MenuSubgroupsLayout === 'DolGuldur'"
    :subgroups="subgroups"
  />
  <menu-subgroups-dol-amroth
    :subgroups="subgroups"
    v-else-if="appConfig.VueSettingsPreRun.MenuSubgroupsLayout === 'DolAmroth'"
  />
</template>

<script setup lang="ts">
import type { Group } from '~types/menuStore'

import { type GUID } from '@arora/common'

const appConfig = useAppConfig()

const route = useRoute()
const router = useRouter()

const subgroups = ref<Group[] | null>(appConfig.Subgroups[route.meta.GroupID as GUID] ?? [])

router.afterEach((to) => refresh(to.meta.GroupID as GUID | undefined))

function refresh(groupId: GUID | undefined): void {
  subgroups.value = groupId ? appConfig.Subgroups[groupId] : null
}
</script>
